import { AiFillStar } from "react-icons/ai";
import { FaV } from "react-icons/fa6";

const data = [
  {
    imageName: "Quiche-Lorraine.png",
    name: "Quiche Lorraine",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 12.0,
    repas: "végétarien",
    repasType: "déjeuner",
    category: "français",
    active: true,
    description:
      "La quiche lorraine est une variante de quiche, une tarte salée de la cuisine lorraine et de la cuisine française, à base de pâte brisée ou de pâte feuilletée, de migaine et de lardons.",
  },
  {
    imageName: "Yakitori.png",
    name: "Yakitori",
    rating: 2.5,
    reviews: "(123 reviews)",
    price: 14.0,
    repas: "végétarien",
    repasType: "déjeuner",
    category: "japonaise",
    active: true,
    description:
      "Yakitori (焼き鳥?, litt. « oiseau grillé ») désigne, dans la cuisine japonaise, des brochettes dont chaque morceau a la taille d'une bouchée, cuites sur un gril. Elles sont traditionnellement à base de poulet.",
  },

  {
    imageName: "Palak-Paneer.png",
    name: "Palak Paneer",
    rating: 3,
    reviews: "(123 reviews)",
    price: 6.0,
    repas: "végétarien",
    repasType: "petit-déjeuner",
    category: "indienne",
    active: false,
    description:
      "Le palak paneer (prononcé : [ paːlək pəniːr ]) est un plat végétarien indien du Pendjab, constitué d'épinards, de sauce tomate et de panir, donnant une purée épaisse assaisonnée avec de l'ail, de l'oignon, du garam masala et d'autres épices.",
  },
  {
    imageName: "Steak frites.png",
    name: "Steak frites",
    rating: 5,
    reviews: "(123 reviews)",
    price: 4.0,
    repas: "non-végétarien",
    repasType: "déjeuner",
    category: "français",
    active: true,
    description:
      "Le steak frites est un plat fréquemment servi en brasserie et dans les restaurants. C'est également un plat familial dans les contrées où les frites font partie des habitudes culinaires.",
  },
  {
    imageName: "Dal-Makhani.png",
    name: "Dal Makhani",
    rating: 1,
    reviews: "(123 reviews)",
    price: 6,
    repas: "végétarien",
    repasType: "petit-déjeuner",
    category: "indienne",
    active: false,
    description:
      "Traduit de l'anglais-Le Dal makhani est un plat originaire de la région du Pendjab. Variation relativement moderne des plats traditionnels à base de lentilles, elle est préparée avec de l'urad dal et d'autres légumineuses, et comprend du beurre et de la crème.",
  },
  {
    imageName: "Biryani.png",
    name: "Biryani",
    rating: 3,
    reviews: "(123 reviews)",
    price: 7.0,
    repas: "non-végétarien",
    repasType: "déjeune",
    category: "indienne",
    active: false,
    description:
      "Le biryani en pachto : بریانی ;en bengali : বিরিয়ানি ; en télougou : బిర్యాని ; en persan : بریانی ; en tamoul : பிரியாணி ; en pendjabi : ਬਿਰਿਆਨੀ ; en malayalam : ബിരിയാണി), est un plat du sous-continent Indien à base de riz (en général, du riz basmati), préparé avec des épices, de la viande, des œufs ou des légumes.",
  },

  {
    imageName: "Pizza.png",
    name: "Pizza",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 5.0,
    repas: "végétarien",
    repasType: "déjeune",
    category: "italienne",
    active: true,
    description:
      "La pizza est une recette de cuisine traditionnelle de la cuisine italienne, originaire de Naples à base de galette de pâte à pain, garnie principalement d'huile d'olive, de sauce tomate, de mozzarella et d'autres ingrédients et cuite au four.",
  },

  {
    imageName: "Soupe-oignon gratinée.png",
    name: "Soupe à l'oignon gratinée",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 5.5,
    repas: "végétarien",
    repasType: "petit-déjeuner",
    category: "français",
    active: true,
    description:
      "La soupe à l’oignon est une soupe gratinée à base d'oignons, traditionnelle de la cuisine française et de nombreuses régions productrices d'oignons. Elle est souvent accompagnée de croûtons et de fromage râpé. ",
  },

  {
    imageName: "Poulet au beurre.png",
    name: "Poulet au beurre",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 8.0,
    repas: "non-végétarien",
    repasType: "dîner",
    category: "indienne",
    active: true,
    description:
      "Le poulet au beurre, butter chicken en Europe francophone, ou murgh makhani, est un plat de la cuisine indienne, vraisemblablement originaire de New Delhi. Ce plat, à base de poulet mariné dans une sauce au beurre, est généralement servi avec du pain naan et du riz.",
  },

  {
    imageName: "Boeuf-Bourguignon.png",
    name: "Boeuf Bourguignon",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 10.0,
    repas: "non-végétarien",
    repasType: "petit-déjeuner",
    category: "français",
    active: true,
    description:
      "Le bœuf bourguignon est une recette de cuisine d'estouffade de bœuf considérée aujourd'hui comme étant traditionnelle de la cuisine bourguignonne, en France. Cuisinée au vin rouge de Bourgogne, avec une garniture de champignons, de petits oignons et de lardons, ses variations d'accompagnement sont multiples.",
  },
  {
    imageName: "Coq-au-vin.png",
    name: "Coq au vin",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 12.0,
    repas: "non-végétarien",
    repasType: "déjeuner",
    category: "français",
    active: true,
    description:
      "Le coq au vin est un mets emblématique de la cuisine française, à base de découpes de coq marinées, puis braisées dans un vin rouge ou blanc, agrémentée d'un bouquet garni, de lardons, de champignons, de carottes et d'oignons.",
  },
  {
    imageName: "Lasagna.png",
    name: "Lasagna",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 14.0,
    repas: "non-végétarien",
    repasType: "non-végétarien",
    category: "italienne",
    active: false,
    description:
      "Les lasagnes sont à la fois des pâtes alimentaires en forme de feuilles rectangulaires, et une recette de cuisine italienne à base de couches alternées de pâtes lasagnes, parmesan, mozzarella, ou ricotta, et de sauce bolognaise ou sauce béchamel, gratinée au four.",
  },
  {
    imageName: "Crêpes.png",
    name: "Crêpes",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 15.0,
    repas: "non-végétarien",
    repasType: "dîner",
    category: "français",
    active: true,
    description:
      "La crêpe est un mets composé d'une couche plus ou moins fine de pâte, faite à base de farine et d'œufs agglomérés à un liquide, sans levain. Elle est généralement de forme ronde. La crêpe se mange chaude ou froide, sucrée ou salée, comme plat principal ou comme dessert, mais peut aussi constituer un en-cas.",
  },
  {
    imageName: "Ratatouille.png",
    name: "Ratatouille",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 17.0,
    repas: "non-végétarien",
    repasType: "dîner",
    category: "français",
    active: true,
    description:
      "La ratatouille est une spécialité culinaire traditionnelle des cuisines niçoise, provençale, occitane et méditerranéenne, à base de ragoût mijoté de légumes méditerranéens, et d'huile d'olive.",
  },

  {
    imageName: "Magret-de-Canard.png",
    name: "Magret de Canard",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 21.0,
    repas: "non-végétarien",
    repasType: "dînerr",
    category: "français",
    active: true,
    description:
      "Le magret est un filet de viande maigre, originaire de la cuisine gersoise, découpé à partir de la poitrine d'une oie ou d'un canard gras, engraissé par gavage, les mêmes qui servent à produire le confit et le foie gras.",
  },
  {
    imageName: "Pasta.png",
    name: "Pâtes",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 9.0,
    repas: "végétarien",
    repasType: "déjeuner",
    category: "italienne",
    active: true,
    description:
      "Pâtes est le terme français pour désigner les aliments à base de pâte, généralement fabriqués à partir de farine de blé dur et d'eau, parfois avec des œufs. Les pâtes peuvent prendre de nombreuses formes et tailles différentes, et sont souvent servies avec une sauce, du fromage ou d'autres ingrédients. Elles sont un plat populaire dans de nombreux pays, en particulier en Italie",
  },
  {
    imageName: "Salade Niçoise.png",
    name: "Salade Niçoise",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 7.0,
    repas: "végétarien",
    repasType: "déjeuner",
    category: "français",
    active: true,
    description:
      "La salade niçoise est une spécialité culinaire de salade composée traditionnelle de la cuisine niçoise, à ce jour répandue dans le monde entier sous de multiples variantes et adaptations. Elle constitue l'une des principales entrées des cuisines provençale et méditerranéenne, voire un repas à part entière.",
  },
  {
    imageName: "Blanquette-de-Veau.png",
    name: "Blanquette de Veau",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 16.0,
    repas: "végétarien",
    repasType: "dîner",
    category: "français",
    active: true,
    description:
      "La blanquette, ou blanquette de veau ou blanquette de veau à l'ancienne, est une recette de cuisine traditionnelle de cuisine française, à base de viande de veau cuite dans un bouillon avec carotte, poireau, oignon et bouquet garni, liée en sauce blanche (roux) et aux champignons de Paris1.",
  },
  {
    imageName: "Soufflé.png",
    name: "Soufflé",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 22.0,
    repas: "déjeuner",
    repasType: "non-végétarien",
    category: "français",
    active: true,
    description:
      "Un soufflé est une préparation culinaire salée ou sucrée, chaude, qui incorpore des blancs d'œufs montés en neige. Un soufflé glacé est une préparation glacée qui incorpore de la crème fouettée. Dans les deux cas ces mousses donnent une texture aérienne et légère tant que le soufflé ne retombe pas...",
  },
  {
    imageName: "Dropa Khatsa.png",
    name: "Dropa Khatsa",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 8.0,
    repas: "végétarien",
    repasType: "déjeuner",
    category: "tibétaine",
    active: true,
    description:
      "Drokpa katsa is a Tibetan cuisine dish of stewed tripe, with curry, fennel, monosodium glutamate and salt.",
  },
  {
    imageName: "Momos.png",
    name: "Momos",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 8.0,
    repas: "non-végétarien",
    repasType: "dîner",
    category: "tibétaine",
    active: true,
    description:
      "Le momo (tibétain : མོག་མོག་, Wylie : mog mog) est un ravioli tibétain, cuit à la vapeur, ou plus rarement bouilli, farci de viande ou de légumes et accompagné d'une sauce piquante. Il est devenu mets traditionnel également au Népal et au Bengale-Occidental (particulièrement à Darjeeling)1. Le momo est très prisé en Asie du Sud et se trouve dans nombre de restaurants et d'étals de rue2.",
  },
  {
    imageName: "Thenthuk.png",
    name: "Thenthuk",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 10.0,
    repas: "végétarien",
    repasType: "déjeuner",
    category: "tibétaine",
    active: true,
    description:
      "Traduit de l'anglais-Thenthuk ou soupe de nouilles tirée à la main, est une soupe de nouilles très courante dans la cuisine tibétaine, en particulier à Amdo, au Tibet, où elle est servie au dîner et parfois au déjeuner.",
  },
  {
    imageName: "Cassoulet.png",
    name: "Cassoulet",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 12.0,
    repas: "végétarien",
    repasType: "déjeuner",
    category: "français",
    active: true,
    description:
      "Le cassoulet (de l'occitan cassolet, caçolet) est une spécialité régionale du Languedoc, à base de haricots secs, généralement blancs, et de viande. À son origine, il était à base de fèves. Le cassoulet tient son nom de la cassole en terre cuite émaillée dite caçòla1 en occitan et fabriquée à Issel.",
  },

  {
    imageName: "Bún chả.png",
    name: "Bún chả",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 20.0,
    repas: "végétarien",
    repasType: "dîner",
    category: "vietnamienne",
    active: true,
    description:
      "Le bò bún ou bún bò nam bộ (bún thịt bò, en vietnamien1, bò « bœuf », bún « vermicelle de riz », et nam bộ « région sud du Viêt Nam ») est une recette traditionnelle de la cuisine vietnamienne, déclinée sous diverses variantes, à base de vermicelles de riz servi à température ambiante, de viande de bœuf émincée et cuite, de crudités, d'herbes aromatiques, et de sauce de poisson sucrée-vinaigrée2.",
  },
  {
    imageName: "Phở.png",
    name: "Phở",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 8.0,
    repas: "non-végétarien",
    repasType: "dîner",
    category: "vietnamienne",
    active: true,
    description:
      "Le phở (en API : /fɤ˧˩˧/ Écouter, prononcé « feu » avec un ton descendant-montant) également appelé pho ou soupe tonkinoise, est un plat traditionnel de cuisine vietnamienne, à base de bouillon de viande et de nouilles de riz, agrémentés de divers ingrédients. Le phở se cuisine traditionnellement avec du bœuf ou encore du poulet. On ne trouve généralement pas de version végétarienne, puisque le bouillon est à base de viande.",
  },
  {
    imageName: "Sushi.png",
    name: "Sushi",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 22.0,
    repas: "non-végétarien",
    repasType: "dîner",
    category: "japonaise",
    active: true,
    description:
      "Le sushi (すし, 寿司, 鮨, 鮓?) est un plat traditionnel japonais, composé d'un riz vinaigré appelé shari (舎利?) combiné avec un autre ingrédient appelé neta (寿司ネタ, sushi-neta?) qui est habituellement du poisson cru ou des fruits de mer. Cette forme d'art culinaire est un des emblèmes de la cuisine japonaise dans le monde, alors que sa consommation n'est qu'occasionnelle au Japon.",
  },
  {
    imageName: "Gỏi Cuốn.png",
    name: "Gỏi Cuốn",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 11.0,
    repas: "non-végétarien",
    repasType: "non-végétarien",
    category: "vietnamienne",
    active: true,
    description:
      "Le rouleau de printemps, appelé gỏi cuốn, bo cuốn ou bi cuốn en vietnamien selon la farce, summer roll en anglais, est une spécialité culinaire du Vietnam. Le rouleau de printemps est également un plat chinois, nommé chūnjuǎn.",
  },
  {
    imageName: "Tempura.png",
    name: "Tempura",
    rating: 4.5,
    reviews: "(123 reviews)",
    price: 21.0,
    repas: "non-végétarien",
    repasType: "déjeuner",
    category: "japonaise",
    active: true,
    description:
      "Les tempuras sont une adaptation japonaise des peixinhos da horta, introduits par des missionnaires jésuites portugais au xviie siècle, et qui existent toujours dans la cuisine portugaise2. Par rapport à la recette portugaise, les Japonais ont allégé la pâte et changé les garnitures3.",
  },
];

export default data;
