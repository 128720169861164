import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../../shared/Footer/Footer";
import "./MainLayout.css";

function MainLayout() {
  return (
    <div id="main-layout">
      <Header />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default MainLayout;
