import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import AuthContext from "../../context/AuthContext";
import CustomModal from "../../components/shared/CustomModal/CustomModal";
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword";
import "./Auth.css";

interface LoginFormState {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { handleLogin, error, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [state, setState] = useState<LoginFormState>({
    email: "",
    password: "",
  });

  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { email, password } = state;

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    setErrorMessage("");
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email || !password) {
      setErrorMessage("An error occurred during login. Please try again");
      return;
    }
    try {
      await handleLogin(email, password);
    } catch (error) {
      console.error(error);
      setErrorMessage(
        "Une erreur s'est produite lors de la connexion. Veuillez réessayer."
      );
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handlePasswordReset = () => {
    setIsModalOpen(true);
    setIsResetPassword(true);
  };

  return (
    <div className="wrapper fadeInDown">
      <div id="formContent">
        <h2 className="active"> SE CONNECTER </h2>

        <div className="fadeIn first">
          <img src="/avatar.jpg" alt="Avatar" id="icon" />
        </div>

        {error && <p className="error">{error}</p>}

        <form onSubmit={handleSubmit}>
          <input
            type="email"
            id="login"
            className="fadeIn second"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleInputChange}
            required
          />
          <input
            type="password"
            id="password"
            className="fadeIn third"
            name="password"
            placeholder="Mot de passe"
            value={password}
            onChange={handleInputChange}
            required
          />
          <input
            type="submit"
            className="fadeIn fourth btn"
            value="Se connecter"
          />
        </form>

        <div className="reset-password-container">
          <button id="reset-password" onClick={handlePasswordReset}>
            Mot de passe oublié
          </button>
        </div>

        <div id="formFooter">
          <div className="auth-footer-link">
            <Link to="/signup" className="underlineHover">
              Inscrivez-vous, si vous êtes un utilisateur existant
            </Link>
            <Link to="/" className="underlineHover">
              Accueil
            </Link>
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}

      {isResetPassword && isModalOpen && (
        <CustomModal onClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
          <IoMdCloseCircle
            className="close-modal-icon"
            onClick={() => setIsResetPassword(false)}
          />
          <ForgotPassword />
        </CustomModal>
      )}
    </div>
  );
};

export default LoginPage;
