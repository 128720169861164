import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./ForgotPassword.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        "http://127.0.0.1:8000/api/password_reset_link",
        { email }
      );
      const { status, message } = response?.data;

      debugger;

      if (response.status === 200 && status === "success") {
        setEmail("");
        toast.success(message);
      } else {
        toast.error("Une erreur s'est produite. Veuillez réessayer.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("Une erreur s'est produite. Veuillez réessayer.");
    }
  };

  return (
    <div id="password-reset-container">
      <p>Entrez l'e-mail à envoyer pour réinitialiser le mot de passe</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <button type="submit" className="fadeIn fourth btn">
          {isLoading ? "Envoi en cours..." : "Soumettre"}
        </button>
      </form>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ForgotPassword;
